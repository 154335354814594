<template>
  <div class="row">
    <div
      v-if="item && item.id"
      class="col-12"
    >
      <div class="card">
        <div class="card-header">
          <h3 class="cart-title">
            <a data-action="collapse">Feedback editor</a>
          </h3>
          <div class="heading-elements">
            <ul class="list-inline mb-0">
              <li>
                <a data-action="collapse"><i data-feather="chevron-down" /></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-content collapse show">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-9">
                <p v-html="item.feedback_researcher" />
              </div>
              <div
                class="col-sm-3 text-end"
                v-html="item.evaluation_table"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="convo.active || loggedUser.roles.includes('super-admin')"
      class="col"
    >
      <div class="card border-success">
        <div class="card-header">
          <h3 class="cart-title">
            <a data-action="collapse">Promotion result</a>
          </h3>
          <div class="heading-elements">
            <ul class="list-inline mb-0">
              <li>
                <a data-action="collapse"><i data-feather="chevron-down" /></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-content collapse show">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-4">
                <div class="mb-1">

                  <div class="row">
                    <p>This evaluation begins <strong>{{ item.year_initial }}</strong> and ends <strong>{{ (item.year_end - 1) }}</strong></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-9">
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Feedback to the researcher</label>
                  <quill-editor v-model="item.feedback_researcher" />
                  <!-- <textarea
                    id=""
                    v-model="item.feedback_researcher"
                    name=""
                    cols="30"
                    rows="10"
                    class="form-control"
                  /> -->
                </div>
              </div>
              <div class="col-sm-3">
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Mark</label>
                  <select
                    v-model="item.grade"
                    class="form-select"
                    @change="selectLevel"
                  >
                    <option value="">
                      None
                    </option>
                    <option value="5">
                      Outstanding
                    </option>
                    <option value="4">
                      Very Good
                    </option>
                    <option value="3">
                      Fair
                    </option>
                    <option value="2">
                      Inadequate
                    </option>
                    <option value="1">
                      Unacceptable
                    </option>
                  </select>
                </div>
                <div class="form-check-success form-switch">
                  <input
                    id="feedbackApproved"
                    v-model="item.feedback_approved"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label ms-1"
                    for="feedbackApproved"
                  > Feedback approved</label>
                </div>
              </div>
            </div>

            <hr>

            <div class="row">
              <!-- <div class="col-sm-4">
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Result <span class="text-danger">*</span></label>
                  <div class="mt-1">
                    <div class="row">
                      <div class="col">
                        <div class="form-check form-check-inline me-2">
                          <input
                            id="Positive"
                            v-model="item.result"
                            class="form-check-input"
                            type="radio"
                            name="PositiveNegative"
                            value="true"
                          >
                          <label
                            class="form-check-label"
                            for="Positive"
                          >Positive</label>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-check form-check-inline me-2">
                          <input
                            id="Negative"
                            v-model="item.result"
                            class="form-check-input"
                            type="radio"
                            name="PositiveNegative"
                            value="false"
                          >
                          <label
                            class="form-check-label"
                            for="Negative"
                          >Negative</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="col-sm-4">
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Additional level(s)</label>
                  <input
                    v-model="item.levels"
                    type="text"
                    class="form-control"
                    :disabled="!item.extraordinary"
                  >
                  <div class="form-check-success form-switch mt-1">
                    <input
                      id="ExtraordinaryEvaluation"
                      v-model="item.extraordinary"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label ms-1"
                      for="ExtraordinaryEvaluation"
                    > Extraordinary evaluation</label>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Late retirement <span class="text-danger">*</span></label>
                  <div class="mt-1">
                    <div class="row">
                      <div class="col">
                        <div class="form-check form-check-inline me-2">
                          <input
                            id="NA"
                            v-model="item.late_retirement"
                            class="form-check-input"
                            type="radio"
                            name="LateRetirement"
                            :value="null"
                            checked=""
                          >
                          <label
                            class="form-check-label"
                            for="NA"
                          >N/A</label>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-check form-check-inline me-2">
                          <input
                            id="Yes"
                            v-model="item.late_retirement"
                            class="form-check-input"
                            type="radio"
                            name="LateRetirement"
                            value="true"
                            checked=""
                          >
                          <label
                            class="form-check-label"
                            for="Yes"
                          >Yes</label>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-check form-check-inline me-2">
                          <input
                            id="No"
                            v-model="item.late_retirement"
                            class="form-check-input"
                            type="radio"
                            name="LateRetirement"
                            value="false"
                            checked=""
                          >
                          <label
                            class="form-check-label"
                            for="No"
                          >No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Years until next promotion</label>
                  <input
                    v-model="item.years_next_prom"
                    type="text"
                    class="form-control"
                  >
                </div>
              </div>
              <div class="col-sm-2">
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Next promotion</label>
                  <date-picker
                    v-model="item.next_promotion"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div
      v-if="convo.active || loggedUser.roles.includes('super-admin')"
      class="page-actions d-flex align-items-center justify-content-between"
    >
      <div>
        <button
          id="type-success"
          class="btn btn-info me-21"
          :class="{'d-none': loading}"
          @click="save()"
        >
          <i data-feather="command" /> Save draft
        </button>
        <!-- <button class="btn btn-outline-info" type="button" disabled="">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="ms-25 align-middle">Updating...</span>
                        </button> -->
        <button
          id="type-success"
          class="btn btn-success ms-1"
          :class="{'d-none': loading}"
          @click="save(true)"
        >
          <i data-feather="save" /> Save & Submit
        </button>
        <button
          class="btn btn-outline-success"
          type="button"
          disabled=""
          :class="{'d-none': !loading}"
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          <span class="ms-25 align-middle">Saving...</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import 'vue2-datepicker/index.css'
import DatePicker from 'vue2-datepicker'

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      item: 'promotionHistory/item',
      user: 'users/user',
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    await this.$store.dispatch('promotionHistory/getCurrentHistory', { convo: this.convo.id, user: this.user.id })
    if (!('late_retirement' in this.item)) {
      this.item.late_retirement = null
    }
    if (!this.item.id) {
      this.item.year_end = this.user.next_promotion_year.toString()
      this.item.year_initial = (Number.parseInt(this.user.next_promotion_year, 0) - 5).toString()
      this.item.years_next_prom = 5
      this.item.next_promotion = (Number.parseInt(this.user.next_promotion_year, 0) + 5).toString()
      this.$forceUpdate()
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    selectLevel() {
      switch (this.item.grade) {
        case '5':
          this.item.levels = 3
          break
        case '4':
          this.item.levels = 2
          break
        case '3':
          this.item.levels = 1
          break
        case '2':
          this.item.levels = 0
          break
        case '1':
          this.item.levels = 0
          break
        default:
      }
    },
    async save(submit = false) {
      this.loading = true
      try {
        this.item.applicant_id = this.user.id
        this.item.promotion_id = this.convo.id
        this.item.feedback_editor = this.loggedUser.id
        this.item.submit = submit
        if (this.item.id) {
          await this.$store.dispatch('promotionHistory/update', this.item)
        } else {
          await this.$store.dispatch('promotionHistory/create', this.item)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.item.id) {
        Vue.swal('The promotion result has been saved correctly!', '', 'success')
      }

      this.loading = false
    },
  },
}
</script>
